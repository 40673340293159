import React from "react";

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  EditButton,
  Edit,
  Create,
  TabbedForm,
  FormTab,
  ReferenceInput,
  AutocompleteInput,
  Filter,
} from "react-admin";

export const AccountUserFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const AccountUserList: React.FC = (props) => (
  <List {...props} filters={<AccountUserFilter />} exporter={false}>
    <Datagrid>
      <ReferenceField label="User" source="userId" reference="User">
        <TextField source="email" />
      </ReferenceField>

      <ReferenceField label="Account" source="accountId" reference="Account">
        <TextField source="name" />
      </ReferenceField>

      <EditButton basePath="/AccountUser" />
    </Datagrid>
  </List>
);

export const AccountUserEdit: React.FC = (props) => (
  <Edit title="Update AccountUser" {...props}>
    <TabbedForm>
      <FormTab label="Basic Details">
        <TextInput source="id" disabled />

        <ReferenceInput label="User Email" source="userId" reference="User">
          <AutocompleteInput optionText="email" />
        </ReferenceInput>

        <ReferenceInput
          label="Account Name"
          source="accountId"
          reference="Account"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const AccountUserCreate: React.FC = (props) => (
  <Create title="Create a AccountUser" {...props}>
    <TabbedForm>
      <FormTab label="Basic Details">
        <ReferenceInput label="User" source="userId" reference="User">
          <AutocompleteInput optionText="email" />
        </ReferenceInput>

        <ReferenceInput label="Account" source="accountId" reference="Account">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
