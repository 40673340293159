import buildOpenCrudProvider, { buildQuery } from "ra-data-opencrud";
import { client } from "./";

const USER_UPDATE_MANY = {
  args: [],
  deprecationReason: null,
  description: "Updates many User's",
  isDeprecated: false,
  name: "updateManyUsers",
  type: { kind: "NON_NULL", name: null, ofType: { name: "BatchPayload" } },
};

const USER_DELETE_MANY = {
  args: [],
  deprecationReason: null,
  description: "Deletes many User's",
  isDeprecated: false,
  name: "deleteManyUsers",
  type: { kind: "NON_NULL", name: null, ofType: { name: "BatchPayload" } },
};

const enhanceBuildQuery =
  (introspection: any) => (fetchType: any, resource: any, params: any) => {
    introspection.resources[0]["UPDATE_MANY"] = USER_UPDATE_MANY;
    introspection.resources[0]["DELETE_MANY"] = USER_DELETE_MANY;

    const builtQuery = buildQuery(introspection)(
      fetchType,
      resource,
      params,
      // @ts-ignore
      null
    );

    return builtQuery;
  };

const buildProvider = () =>
  // @ts-ignore
  buildOpenCrudProvider({ client, buildQuery: enhanceBuildQuery });

export default buildProvider;
