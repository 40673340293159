export const endpoint: string = "https://svenska-alarm-app.eu.auth0.com";
export const clientId: string = "OLNlDaabJYID8ghD2RLh4FLKBjuE0ov0";

export interface LoginSuccess {
  access_token: string;
  id_token: string;
  scope: string;
  expired_in: number;
  token_type: string;
}

export interface LoginFailure {
  error: object;
}

type GetAccessToken = (
  username: string,
  password: string
) => PromiseLike<LoginSuccess | LoginFailure>;

export function isLoginSuccess(
  response: LoginSuccess | LoginFailure
): response is LoginSuccess {
  return (response as LoginFailure).error === undefined;
}

export const attemptLogin: GetAccessToken = (username, password) => {
  return fetch(`${endpoint}/oauth/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      grant_type: "http://auth0.com/oauth/grant-type/password-realm",
      realm: "Email-Password-Admins",
      client_id: clientId,
      scope: "openid",
      username: username,
      password: password,
    }),
  })
    .then((r) => r.json())
    .then((json) => json)
    .catch((err) => ({ error: err }));
};
