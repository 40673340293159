import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { concat } from "apollo-link";
import { onError } from "apollo-link-error";

import { httpURI } from "./links";

export const httpLink = new HttpLink({
  uri: httpURI,
});

// Add authorization header to every outgoing request with the token we
// have saved in sessionStorage
export const authMiddleware = setContext(async (req, { headers }) => {
  const accessToken = sessionStorage.getItem("accessToken");

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

// If any GraphQL request comes back with a 401 we will assume the access
// token is invalid or has expired and crudely log the user out
export const logoutAfterware = onError(({ networkError }) => {
  // @ts-ignore
  if (networkError && networkError.statusCode === 401) {
    sessionStorage.removeItem("accessToken");
    window.location.href = "/";
  }
});

// Glue logoutAfterware and authMiddleware together in a chain
const wares = logoutAfterware.concat(authMiddleware);

export const client = new ApolloClient({
  link: concat(wares, httpLink),
  cache: new InMemoryCache(),
});
