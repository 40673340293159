export const validatePhoneNumber: (
  value: string
) => string | undefined | null = (value) => {
  // If the value is not yet set, do not attempt to validate
  if (value === undefined || value === null) {
    return undefined;
  }

  // The value must start with a double zero
  if (value.indexOf("00") !== 0) {
    return "start will 00 include land number and no spaces or special signs";
  }

  // The value must not contain any spaces
  if (value.indexOf(" ") > -1) {
    return "Number must not contain any spaces";
  }

  // The value should contain only numerical digits
  if (/^\d+$/.test(value) === false) {
    return "Number must contain only numerical digits";
  }

  return undefined;
};
