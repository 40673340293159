import React from "react";
import SvenskaAlarmLogo from "../assets/svenska-alarm-logo.png";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface Props {}

export const SplashScreen: React.FC = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
    }}
  >
    <div style={{ marginBottom: "50px" }}>
      <img
        src={SvenskaAlarmLogo}
        alt="Svenska Alarm"
        height="120"
        width="auto"
      />
    </div>

    <div>
      <CircularProgress />
    </div>
  </div>
);
