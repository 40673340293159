import React from "react";
import { Admin, CreateProps, EditProps, Resource } from "react-admin";

import PanelIcon from "@material-ui/icons/AspectRatio";
import AccountIcon from "@material-ui/icons/DeviceHub";
import AccountUserIcon from "@material-ui/icons/GroupWork";
import UserIcon from "@material-ui/icons/People";
import ResponseServiceIcon from "@material-ui/icons/RecordVoiceOver";

import decodeJwt, { JwtPayload } from "jwt-decode";

import { AccountList, AccountCreate, AccountEdit } from "./components/Accounts";
import {
  AccountUserList,
  AccountUserCreate,
  AccountUserEdit,
} from "./components/AccountUsers";
import { Login } from "./components/Login";
import { PanelList, PanelCreate, PanelEdit } from "./components/Panels";
import {
  ResponseServiceList,
  ResponseServiceCreate,
  ResponseServiceEdit,
} from "./components/ResponseServices";
import { SplashScreen } from "./components/SplashScreen";
import { UserList, UserCreate, UserEdit } from "./components/Users";

import buildOpenCrudProvider from "./graphql/dataProvider";

export interface State {
  dataProvider: any;
  userType: "Admin" | "Technician" | null;
  accessToken: string | null;
}

export interface Props {}

interface DolphinAdminJwtPayload extends JwtPayload {
  "https://admin.dolphin.svenskaalarm.se/user_metadata": string;
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const accessToken = sessionStorage.getItem("accessToken");
    const userType = accessToken ? this.getUserType(accessToken) : null;
    this.state = {
      dataProvider: null,
      userType,
      accessToken,
    };
  }

  getUserType(idToken: string) {
    // Decode the JWT we received from Auth0
    const decodedJwt = decodeJwt<DolphinAdminJwtPayload>(idToken);

    if (decodedJwt["https://admin.dolphin.svenskaalarm.se/user_metadata"]) {
      const userMetadata: any =
        decodedJwt["https://admin.dolphin.svenskaalarm.se/user_metadata"];

      return userMetadata.isTechnician === "true" ? "Technician" : "Admin";
    }
    return "Admin";
  }

  // Handle when the User logs in successfully by setting aside their access
  // token for later use and determining what type of user they are
  handleLoginSuccess(idToken: string) {
    const accessToken = idToken;
    const userType = this.getUserType(accessToken);
    this.setState({
      accessToken,
      userType,
    });
  }

  render() {
    const { dataProvider, accessToken } = this.state;

    // Render a login page if no access token exists for the current user
    if (!accessToken) {
      return (
        <Login onLoginSuccess={(idToken) => this.handleLoginSuccess(idToken)} />
      );
    }

    // If the data provider is still being set up, render a splash/loading
    // screen
    if (!dataProvider) {
      buildOpenCrudProvider().then((dataProvider: any) =>
        this.setState({ dataProvider })
      );
      return <SplashScreen />;
    }

    return (
      <Admin dataProvider={dataProvider}>
        <Resource
          name="Account"
          icon={AccountIcon}
          list={AccountList}
          edit={(props: EditProps) => (
            <AccountEdit isAdmin={this.state.userType === "Admin"} {...props} />
          )}
          create={(props: CreateProps) => (
            <AccountCreate
              isAdmin={this.state.userType === "Admin"}
              {...props}
            />
          )}
        />

        <Resource
          name="Panel"
          icon={PanelIcon}
          list={PanelList}
          edit={PanelEdit}
          create={PanelCreate}
        />

        <Resource
          name="User"
          icon={UserIcon}
          list={UserList}
          edit={(props: EditProps) => (
            <UserEdit isAdmin={this.state.userType === "Admin"} {...props} />
          )}
          create={(props: CreateProps) => (
            <UserCreate isAdmin={this.state.userType === "Admin"} {...props} />
          )}
        />

        <Resource
          name="AccountUser"
          icon={AccountUserIcon}
          list={AccountUserList}
          edit={AccountUserEdit}
          create={AccountUserCreate}
          options={{ label: "Membership" }}
        />

        <Resource
          name="ResponseService"
          icon={ResponseServiceIcon}
          list={
            this.state.userType === "Admin" ? ResponseServiceList : undefined
          }
          edit={ResponseServiceEdit}
          create={ResponseServiceCreate}
          options={{ label: "Response Services" }}
        />

        <Resource name="Franchise" />
      </Admin>
    );
  }
}

export default App;
