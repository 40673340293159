import * as React from "react";

export interface Props {
  label: string;
  source: string;
  record?: any;
}

const YesNoField: React.FC<Props> = (props) => {
  // Grab the field from the record
  const value = props.record && props.record[props.source];

  // Return "Yes" if the value is literally 'true'
  if (value === true) {
    return <span>Yes</span>;
  }

  // Return "No" if the value is literally 'false'
  if (value === false) {
    return <span>No</span>;
  }

  // Return null as a fallback
  return null;
};

export default YesNoField;
