import React, { useState, SyntheticEvent } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { attemptLogin, isLoginSuccess } from "../api/Auth0";

interface FormValues {
  email: string;
  password: string;
}

export interface Props {
  onLoginSuccess: (accessToken: string) => void;
}

export const Login: React.FC<Props> = (props) => {
  const [values, setValues] = useState<FormValues>({ email: "", password: "" });
  const [submitting, setSubmitting] = useState(false);
  const [flash, setFlash] = useState<string | null>(null);

  const classes = useStyles();

  const handleSubmit: (event: SyntheticEvent) => void = (event) => {
    event.preventDefault();
    setSubmitting(true);
    setFlash(null);

    attemptLogin(values.email, values.password).then((result) => {
      if (isLoginSuccess(result)) {
        sessionStorage.setItem("accessToken", result.id_token);
        props.onLoginSuccess(result.id_token);
      } else {
        setFlash("Incorrect email or password");
      }

      setSubmitting(false);
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Helmet>
        <title>Admin Login | Svenska Alarm</title>
      </Helmet>

      <CssBaseline />

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Svenska Alarm - Admin Login
        </Typography>

        <form onSubmit={(e) => handleSubmit(e)} className={classes.form}>
          <TextField
            value={values.email}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            disabled={submitting}
          />

          <TextField
            value={values.password}
            onChange={(e) => setValues({ ...values, password: e.target.value })}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={submitting}
          />

          {flash && (
            <Typography align="center" color="error" component="h4">
              {flash}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitting}
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
