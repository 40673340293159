import React from "react";

import {
  ArrayInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FormTab,
  List,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";

export const ResponseServiceList: React.FC = (props) => (
  <List {...props} exporter={false}>
    <Datagrid>
      <TextField source="name" label="Response service name" />
      <TextField source="contactNumber" />
      <TextField source="contactEmail" />

      <EditButton basePath="/ResponseService" />
    </Datagrid>
  </List>
);

export const ResponseServiceEdit: React.FC = (props) => (
  <Edit title="Update ResponseService" {...props}>
    <TabbedForm>
      <FormTab label="Basic details">
        <TextInput source="id" disabled />

        <TextInput source="name" label="Response service name" />
        <TextInput source="contactNumber" label="Company contact number" />
        <TextInput source="contactEmail" label="Company contact email" />
      </FormTab>

      <FormTab label="Dispatch Email">
        <ArrayInput source="dispatchEmailAddresses">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Dispatch SMS">
        <ArrayInput source="dispatchSmsNumbers">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ResponseServiceCreate: React.FC = (props) => (
  <Create title="Create ResponseService" {...props}>
    <TabbedForm>
      <FormTab label="Basic Details">
        <TextInput source="name" label="Response service name" />
        <TextInput source="contactNumber" label="Company contact number" />
        <TextInput source="contactEmail" label="Company contact email" />
      </FormTab>

      <FormTab label="Dispatch Email">
        <ArrayInput source="dispatchEmailAddresses">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Dispatch SMS">
        <ArrayInput source="dispatchSmsNumbers">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
