import React, { useState, useEffect } from "react";
import { validatePhoneNumber } from "../utils/validators";

import {
  Create,
  CreateProps,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  EditProps,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useDataProvider,
} from "react-admin";

export const UserFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const UserList: React.FC = (props) => (
  <List {...props} filters={<UserFilter />} exporter={false}>
    <Datagrid>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="phoneNumber" />
      <ReferenceField
        label="Betalportal"
        source="franchiseId"
        reference="Franchise"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="updatedAt" />
      <DateField source="insertedAt" />
      <EditButton basePath="/User" />
    </Datagrid>
  </List>
);

interface UserEditProps extends EditProps {
  isAdmin: boolean;
}

export const UserEdit: React.FC<UserEditProps> = ({ isAdmin, ...props }) => {
  return (
    <Edit title="Update User" {...props}>
      <SimpleForm>
        <TextInput source="id" disabled />
        <TextInput source="auth0Id" label="Auth0 ID" />

        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="email" />
        <TextInput source="phoneNumber" validate={validatePhoneNumber} />

        <ReferenceInput
          label="Betalportal"
          source="franchiseId"
          reference="Franchise"
          allowEmpty
          disabled={!isAdmin}
        >
          <SelectInput />
        </ReferenceInput>

        <TextInput source="password" type="password" label="New Password" />

        <TextField source="updatedAt" />
        <TextField source="insertedAt" />
      </SimpleForm>
    </Edit>
  );
};

interface UserCreateProps extends CreateProps {
  isAdmin: boolean;
}

export const UserCreate: React.FC<UserCreateProps> = ({
  isAdmin,
  ...props
}) => {
  // Set up a data provider to query for franchises
  const dataProvider = useDataProvider();

  // Find the default franchise, which is empty string to start with
  const [defaultFranchise, setDefaultFranchise] = useState("");

  // Query for franchises and find the uuid for Svenska Alarm
  useEffect(() => {
    dataProvider
      .getList<{ id: string; name: string }>("Franchise", {
        pagination: { page: 1, perPage: 25 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        // Loop over franchises
        for (const franchise of data) {
          // Set uuid for Svenska Alarm as default if available
          if (franchise.name === "SAG") {
            setDefaultFranchise(franchise.id);
            return;
          }
        }
      });
  }, [dataProvider]);

  return (
    <Create title="Create a User" {...props}>
      <SimpleForm>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="email" />
        <TextInput source="phoneNumber" validate={validatePhoneNumber} />

        <ReferenceInput
          label="Betalportal"
          source="franchiseId"
          reference="Franchise"
          allowEmpty
          initialValue={defaultFranchise}
          disabled={!isAdmin}
        >
          <SelectInput />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
