import React, { useMemo } from "react";

import {
  Create,
  CreateProps,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  EditProps,
  Filter,
  FormTab,
  FunctionField,
  List,
  Record,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";

const PROFILE_TYPE_ALL_OPTIONS = [
  { id: "ONE_ONE_TWO_ONLY", value: "Direct 112" },
  { id: "MANUAL_DISPATCH", value: "Guard Standby" },
  { id: "AUTO_DISPATCH", value: "Guard Direct" },
  { id: "AUTO_DISPATCH_VERIFIED", value: "Guard Direct for verified alarm" },
];

const PROFILE_TYPE_TECHNICIAN_OPTIONS = [
  { id: "ONE_ONE_TWO_ONLY", value: "Direct 112" },
  { id: "MANUAL_DISPATCH", value: "Guard Standby" },
  { id: "AUTO_DISPATCH_VERIFIED", value: "Guard Direct for verified alarm" },
];

const PROFILE_TYPE_TECHNICIAN_EDIT_OPTIONS = [
  { id: "ONE_ONE_TWO_ONLY", value: "Direct 112" },
  { id: "MANUAL_DISPATCH", value: "Guard Standby" },
  { id: "AUTO_DISPATCH", value: "Guard Direct", disabled: true },
  { id: "AUTO_DISPATCH_VERIFIED", value: "Guard Direct for verified alarm" },
];

/**
 * Function to translat profile type ids to readable values
 *
 * Returns empty string if profile type could not be matched
 */
export const getProfileTypeName = (
  record: Record | undefined,
  source: string | undefined
) => {
  // Check the empty case firssst
  if (!record || !source) return "";

  // Get the profile type id
  const profileType = record[source];

  // Try to find it among above options
  for (const profileTypeOption of PROFILE_TYPE_ALL_OPTIONS) {
    if (profileTypeOption.id === profileType) {
      // Return if found
      return profileTypeOption.value;
    }
  }

  // Otherwise, just return the id
  return profileType || "";
};

export const AccountFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const AccountList: React.FC = (props) => (
  <List {...props} filters={<AccountFilter />} exporter={false}>
    <Datagrid>
      <TextField source="name" label="Account name" />
      <FunctionField
        source="profileType"
        label="Response"
        render={getProfileTypeName}
      />
      <DateField source="updatedAt" />
      <DateField source="insertedAt" />

      <EditButton basePath="/Account" />
    </Datagrid>
  </List>
);

interface AccountEditProps extends EditProps {
  isAdmin: boolean;
}

export const AccountEdit: React.FC<AccountEditProps> = ({
  isAdmin,
  ...props
}) => {
  // Exclude legacy options for non-admin users
  const inputChoices = useMemo(() => {
    if (isAdmin) return PROFILE_TYPE_ALL_OPTIONS;
    return PROFILE_TYPE_TECHNICIAN_EDIT_OPTIONS;
  }, [isAdmin]);

  // Validate profile type
  const validateProfileType = useMemo(
    () => (value: string) => {
      if (isAdmin) return undefined;
      if (value === "AUTO_DISPATCH") {
        return "This option has been disabled";
      }
    },
    [isAdmin]
  );

  return (
    <Edit title="Update Account" {...props}>
      <TabbedForm>
        <FormTab label="Basic Details">
          <TextInput source="id" disabled />
          <TextInput source="name" label="Account name" />

          <SelectInput
            source="profileType"
            label="Response"
            optionText="value"
            choices={inputChoices}
            validate={validateProfileType}
          />

          <DateInput source="insertedAt" />
          <DateInput source="updatedAt" />
        </FormTab>

        <FormTab label="Panels">
          <ReferenceManyField
            label="Panels on account"
            reference="Panel"
            target="accountId"
          >
            <Datagrid>
              <TextField source="name" />
              <TextField source="objectId" />
              <TextField source="status" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

interface AccountCreateProps extends CreateProps {
  isAdmin: boolean;
}

export const AccountCreate: React.FC<AccountCreateProps> = ({
  isAdmin,
  ...props
}) => {
  // Exclude legacy options for non-admin users
  const inputChoices = useMemo(() => {
    if (isAdmin) return PROFILE_TYPE_ALL_OPTIONS;
    return PROFILE_TYPE_TECHNICIAN_OPTIONS;
  }, [isAdmin]);

  return (
    <Create title="Create an Account" {...props}>
      <SimpleForm>
        <TextInput source="name" label="Account name" />
        <SelectInput
          source="profileType"
          label="Response"
          optionText="value"
          choices={inputChoices}
        />
      </SimpleForm>
    </Create>
  );
};
